<template>
  <div>
    <h1>autoschadeland</h1>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'autoschadeland',
  modules: [
      'userManagement'
  ]
}
</script>
